
<template>

  <div class="main-div-card-bunk">
    <div>
      <div class="choices-main-div">
        <!-- component postCard (names and post of the candidate), pass data applies -->
        <post-intitule class="header-card" :valueData="value" />

        <div class="main-div-entitled">

          <div class="choice">
            <!-- components refuse, validate and suppression applies -->
            <validate-icon
              @click="modificationProfile(2)"
              class="validate button-check"
            />
            <refuse-icon class="refuse button-check" @click="modificationProfile(3)" />

            <div class="dropdown is-right is-active">
              <!-- little three points wrapper -->
              <div class="dropdown-trigger">
                <button
                  @click="dropDownButton"
                  class="button"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu6"
                >
                  <points class="points" />
                </button>
              </div>

              <div
                v-show="dropDown"
                class="dropdown-menu"
                id="dropdown-menu6"
                role="menu"
              >
              <!-- content of dropdown little three points -->
                <div class="dropdown-content">
                  <div class="dropdown-item">
                    <div class="refus-list">
                      <svg-icon
                        class="mr-2"
                        type="mdi"
                        :path="cancel"
                        aria-hidden="true"
                      />
                      <input
                        class="input-refuse"
                        type="button"
                        @click="dropDownButton(), modificationProfile(4)"
                        value="Ne correspond pas au profil"
                      />
                    </div>
                    <div class="refus-list">
                      <svg-icon
                        type="mdi"
                        :path="deleteDefinitively"
                        aria-hidden="true"
                      /><input
                        @click="dropDownButton(), modificationProfile()"
                        class="input-refuse"
                        type="button"
                        value="Supprimer cette candidature"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- component personal informations of the candidate, pass data applies at the component -->
      <personal-informations-bunk :value="value" />

      <!-- component documents of the candidate, pass data applies at the component, !!not working dynamically!! -->
      <drop-down title="Documents partagés">
        <template v-slot:content>
          <div class="main-div-doc">
            <card-document>
              <template v-slot:acronyme-doc> <a v-if="value.apply.data[0].document.length > 0" :href="`https://stardust.green-conseil.com/api/documents/${value.apply.data[0].document[0].hash}/download`" ><span class="file-type">CV</span> </a> <span v-if="value.apply.data[0].document.length === 0">CV</span> </template>
            </card-document>
          </div>
        </template>
      </drop-down>
      <drop-down title="Motivations">
      <!-- motivations of the candidate with called data applies-->
        <template v-slot:content>
          <div class="motivation">
            <p>{{ value.apply.data[0].why_me }}</p>
          </div>
        </template>
      </drop-down>
    </div>

  </div>

</template>

<script>
import postIntitule from "../components/cards/postCard.vue";
import personalInformationsBunk from "../components/cards/personalInformationsBunk.vue";
import dropDown from "../components/cards/dropDown.vue";
import cardDocument from "../components/cards/cardDocument.vue";
import validateIcon from "../components/buttons/validateIcon.vue";
import refuseIcon from "../components/buttons/refuseIcon.vue";
import points from "../components/buttons/points.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import axios from 'axios';
import { mdiCancel, mdiDeleteOutline } from "@mdi/js";

 
export default {
  components: {
    postIntitule,
    personalInformationsBunk,
    dropDown,
    cardDocument,
    validateIcon,
    refuseIcon,
    points,
    SvgIcon,
  },
  props: ["value"],
  data() {
    return {
      dropDown: false,
      cancel: mdiCancel,
      deleteDefinitively: mdiDeleteOutline,
      qualification: null,
    };
  },
  created(){

  },
  methods: {

    modificationProfile(status = null){
      if(status){
        axios.put(`${process.env.VUE_APP_API_CANDIDATES}/candidates/${this.value.id}`, {status: status},  { withCredentials: true });
      } else {
        axios.delete(`${process.env.VUE_APP_API_CANDIDATES}/candidates/${this.value.id}`, {
            withCredentials: true
          })
      }
       this.$emit('remove')
      
    },
    dropDownButton() {
        // function to open & close dropdown informations 
      this.dropDown = !this.dropDown;
    },
  },
};
</script>

<style lang="scss" scoped>

@import '../assets/variables'; 

.main-div-card-bunk {
  max-height: 70vh;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
}

.main-div-doc {
  display: flex;
}

.motivation {
  padding: 0.5rem;
  margin-right: auto;
  margin-left: auto;
  text-align: justify;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
}
.motivation p {
  padding-right: 0.5rem;
  overflow-y: scroll;
  max-height: 18.25rem;
}

.motivation p::-webkit-scrollbar-track {
  border-radius: 6px;
  border-radius: 12px;
  background-color: $color-scrollbar;
}

.file-type{
  color: $color-primary
}
.motivation p::-webkit-scrollbar {
  width: 6px;
  border-radius: 12px;
  background-color: $color-scrollbar;
}

.motivation p::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #585a5c;
}

.main-div-card-bunk::-webkit-scrollbar-track {
  border-radius: 12px;
  background-color: $color-scrollbar;
}

.main-div-card-bunk::-webkit-scrollbar {
  width: 6px;
  border-radius: 12px;
  background-color: $color-scrollbar;
}

.main-div-card-bunk::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #949494;
}

.header-card {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
}

.main-div-entitled {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.post-entitled {
  width: 5.5rem;
  height: 3rem;
  background-color: $color-primary;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.button-check {
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}

.choice {
  display: flex;
  align-items: center;
}

.choices-main-div {
  display: flex;
  justify-content: space-between;
}

.points {
  cursor: pointer;
}

.button {
  border: none;
  outline: none;
}
.button:focus {
  outline: none !important;
}
.refus-list {
  display: flex;
  justify-content: right;
  margin: 5px;
}
.refus-list:hover {
  color: $color-suppression;
  border-left: 3px solid $color-suppression;
}
.input-refuse {
  border: none;
  background: none;
  font-family: $font-primary;
  cursor: pointer;
}
.input-refus:hover {
  color: $color-suppression;
  cursor: pointer;
}


.toto-enter-from{
  opacity: 0;
}
.toto-enter-to{
  opacity: 1;
}
.toto-enter-active{
  transition: all 2s ease;
}

.toto-leave-from{
  opacity: 1;
}
.toto-leave-to{
  opacity: 0;
}
.toto-leave-active{
  transition: all 2s ease;
}
</style>
