<template>
  <div class="main-div">
    <i class="fas">
      <slot />
    </i>
    <div class="div-content-infos">
      <span class="entitled-subtitle-icon"> <slot name="subtitle-icon" /></span>
      <span class="content-infos"> <slot name="content-infos" /> </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>

@import "../assets/variables";

.main-div {
  display: flex;
  margin: 1rem;
  align-items: center;
}

.entitled-subtitle-icon {
  margin-bottom: 0;
  margin-left: 5px;
  font-family: $font-primary;
  font-size: $font-x-small;
  color: $color-light;
}
.content-infos {
  margin-left: 5px;
  font-size: $font-medium;
  font-weight: 500;
  line-height: 24px;
  font-family: $font-primary-medium;
}

.fas {
  width: 2rem;
  height: 2rem;
  background-color: #e7e8e8;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.div-content-infos {
  display: flex;
  flex-direction: column;
}
</style>
