 <!--main page of bunk-->
<template>

  <div class="main-content-bunk" v-if="getListBannette">
    <div class="title-bunk-table mt-4">
      <h1 class="main-title">Candidatures à traiter</h1>
      <div class="pagination">
        <!--pagination functions with buttons chevron left&right -->
        <button
        
          :disabled="page == 1"
          @click="page--, swipe == !swipe"
        > 
          <svg-icon type="mdi" :path="chevronLeft" aria-hidden="true" />
        </button>
        
        <button type="button" @click="page++" :disabled="page >= pages.length">
          <svg-icon type="mdi" :path="chevronRight" aria-hidden="true" />
        </button>
        <span> {{ getListBannette.length }} candidatures à traiter </span>
      </div>
    </div>

    <div class="columns main-div-bunk">
    
      <template v-if="getListBannette.length > 0">
        <!-- loop for applies -->
          <div
            class="column is-4"
            v-for="(data, index) in displayedApplies"
            :key="index"
          >
            <card-bunk
              class="list-complete-item"
              :value="data"
             @remove="getListBannette.splice(index, 1)"
            />
            
          </div>
      </template>
    </div>
    
  </div>
</template>

<script>
import CardBunk from "./CardBunk.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import { mapActions, mapGetters} from 'vuex';


export default {
  components: {
    CardBunk,
    SvgIcon,
  },
  data() {
    return {
      candidates: [],
      applies: [],
      page: 1,
      perPage: 3,
      pages: [],
      chevronLeft: mdiChevronLeft,
      chevronRight: mdiChevronRight,
      swipe: true,
    };
  },
  computed: {
    ...mapGetters([
      'getListBannette'
    ]),
    displayedApplies() {
      // display applies per pages with function paginate()
      return this.paginate(this.getListBannette);
    },
  },
  methods: {
     ...mapActions([
      'updateFilterBunk', 
    ]),
    paginate(candidates) {
      //function for pagination,based on numbers of item (candidates's table) per page
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage + 1;
      let result = candidates.slice(from, to);
      return result;
    },
    setPages() {
      // function for calculate number of pages based on candidates table with loop
      let numberOfPages = Math.ceil(this.getListBannette.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
  },
  created() {
   this.$store.dispatch("fetchCandidatesList"); 
  },
  watch: {
    getListBannette() {
      // immediate function for return setPages()
      this.setPages();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/variables";

.main-div-bunk {
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.main-title {
  font-family: $font-primary;
  font-size: 20px;
  margin-left: 0.75rem;
}

.title-bunk-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paraph-filtre {
  font-family: $font-primary;
  margin-left: 32px;
  font-size: $font-medium;
  margin-bottom: 1rem;
}

.filtres-main-div {
  display: flex;
}

.postes-suivis {
  margin-left: 1rem;
  font-family: $font-primary;
  font-size: $font-small;
}

.pagination {
  margin-right: 20px;
  button {
    border: none;
    background-color: white;
  }
}

.fade {
  transition: opacity 0.5s;
}
.list-group {
  transition: all 1s;
}

.list-bunk-enter {
  transition: all 0.3s ease;
}

.toto-enter-from{
  opacity: 0;
}
.toto-enter-to{
  opacity: 1;
}
.toto-enter-active{
  transition: all 2s ease;
}

.toto-leave-from{
  opacity: 1;
}
.toto-leave-to{
  opacity: 0;
}
.toto-leave-active{
  transition: all 2s ease;
}
//media mobile responsive

@media (max-width: 640px) {
  .main-content-bunk {
    margin-top: 11rem;
  }
}
</style>
