<template>
  <div class="main-div-entitled">
    <div class="post-entitled">
      <span v-if="valueData.apply.data[0].job_id == 1">CDP</span>
      <span v-if="valueData.apply.data[0].job_id == 2">UX/UI</span>
      <span v-if="valueData.apply.data[0].job_id == 3">PO</span>
      <span v-if="valueData.apply.data[0].job_id == 4">MARK</span>
      <span v-if="valueData.apply.data[0].job_id == 5">CDPRO</span>
      <span v-if="valueData.apply.data[0].job_id == 6">DA</span>
      <span v-if="valueData.apply.data[0].job_id == 7">ContractM</span>
      <span v-if="valueData.apply.data[0].job_id == 8">Ing Com</span>
    </div>
    <p>
     {{ valueData.first_name }}
      <span class="last-name">{{ valueData.last_name }}</span>
    </p>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["valueData"],
};
</script>

<style lang="scss" scoped>

@import "../../assets/variables";

.main-div-entitled {
  display: flex;
  justify-content: space-around;
  align-items: center;
  p {
    font-family: $font-primary;
  }
}
.post-entitled {
  width: 5.5rem;
  height: 3rem;
  background-color: $color-primary;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-right: 30px;
  text-align: center;
}

.last-name {
  text-transform: uppercase;
}
</style>
