<!-- Page personal's informations of candidates-->
<template>
  <div>
    <drop-down title="Informations personnelles">
      <template v-slot:content>
        <div class="main-div-dropdown">
          <div class="left-div">

            <!--Experience template-->
            <circle-icon>
              <svg-icon class="svg-icon" type="mdi" :path="work"></svg-icon>
              <template v-slot:subtitle-icon> Expérience </template>
              <template v-slot:content-infos>
                <span v-if="this.value.apply.data[0].experience < 2">
                  {{ value.apply.data[0].experience }} an
                </span>
                <span v-if="this.value.apply.data[0].experience > 1">
                  {{ value.apply.data[0].experience }} ans
                </span>
              </template>
            </circle-icon>

            <!--Remuneration template-->
            <circle-icon>
              <svg-icon class="svg-icon" type="mdi" :path="euros"></svg-icon>
              <template v-slot:subtitle-icon> Rémunération </template>
              <template v-slot:content-infos>
                {{ value.apply.data[0].remuneration }}
                <span
                  class="span-light-infos"
                  v-if="value.apply.data[0].intervention_id == 3 || value.apply.data[0].intervention_id == 2"
                >
                  /an
                </span>
                <span
                  class="span-light-infos"
                  v-if="value.apply.data[0].intervention_id == 1"
                >
                  /jour
                </span>
              </template>
            </circle-icon>


          <!--Intervention template-->
                      <circle-icon>
                        <svg-icon
                          class="svg-icon"
                          type="mdi"
                          :path="intervention"
                        ></svg-icon>
                        <template v-slot:subtitle-icon> Statut </template>
                        <template v-slot:content-infos>
                          <span v-if="value.apply.data[0].intervention_id == 1">Freelance</span>
                          <span v-if="value.apply.data[0].intervention_id == 2">Salariat</span>
                          <span v-if="value.apply.data[0].intervention_id == 3">Alternance</span>
                          <span v-if="value.apply.data[0].intervention_id == 4">Stage</span>
                        </template>
                      </circle-icon>

          </div>


          <div class="right-div">


             <!--Age template-->
            <circle-icon>
              <svg-icon class="svg-icon" type="mdi" :path="birthday"></svg-icon>
              <template v-slot:subtitle-icon> Age </template>
              <template v-slot:content-infos> {{ calculAge }} </template>
            </circle-icon>

            <!--City template-->
            <circle-icon>
              <svg-icon class="svg-icon" type="mdi" :path="location"></svg-icon>
              <template v-slot:subtitle-icon> Ville </template>
              <template v-slot:content-infos>
                <span class="city"> {{ getCity  }} </span>
              </template>
            </circle-icon>

           <!--LinkdIn template-->
            <circle-icon>
              <svg-icon class="svg-icon" type="mdi" :path="linkedin"></svg-icon>
              <template v-slot:subtitle-icon> Linkedin </template>
              <template v-slot:content-infos>
                <a :href="value.linkedin" class="link-linkedin">{{ value.linkedin }} </a>
              </template>
            </circle-icon>

          </div>
        </div>
      </template>
    </drop-down>
  </div>
</template>

<script>
import dropDown from "./dropDown.vue";
import circleIcon from "../CircleIcon";
import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiFolder,
  mdiLinkedin,
  mdiCakeVariant,
  mdiGesture,
  mdiMapMarker,
  mdiBriefcase,
  mdiCurrencyEur,
} from "@mdi/js";
import { dataApplies } from "../../views/Bannette.vue";

// creation for calculate age with birthdate
const getAge = (birthDate) =>
  Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10);

export default {
  components: {
    SvgIcon,
    dropDown,
    circleIcon,
  },
  data() {
    return {
      folder: mdiFolder,
      linkedin: mdiLinkedin,
      birthday: mdiCakeVariant,
      intervention: mdiGesture,
      location: mdiMapMarker,
      work: mdiBriefcase,
      euros: mdiCurrencyEur,
      dataApplies: dataApplies,
    };
  },
  props: ["value"],
  computed: {
    calculAge() {
      // function calcul age of candidate with birthdate
      console.log(this.value.birth_at);
      return this.value.birth_at ? getAge(this.value.birth_at) + " ans" : "NC";
    },
    getCity() {
      return this.value.city ?? "NC"
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/variables";

.main-div-dropdown {
  display: flex;
}
.svg-icon {
  width: 1rem;
  height: 1rem;
}

p {
  margin: 0;
}

.span-light-infos {
  font-size: $font-x-small;
  font-family: $font-primary;
  color: $color-light;
}

.link-linkedin {
  font-size: $font-small;
}

.left-div,
.right-div {
  width: 100%;
}

.city {
  text-transform: uppercase;
}
</style>
