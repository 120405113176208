<!-- refusal cross svg-->
<template>
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_237_4309)">
<rect x="4" y="3" width="40" height="40" rx="4" fill="#FF3760"/>
<path d="M31 17.41L29.59 16L24 21.59L18.41 16L17 17.41L22.59 23L17 28.59L18.41 30L24 24.41L29.59 30L31 28.59L25.41 23L31 17.41Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d_237_4309" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="3"/>
<feGaussianBlur stdDeviation="3"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_237_4309"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_237_4309" result="shape"/>
</filter>
</defs>
</svg>
</template>